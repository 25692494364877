<template>
    <div class="plan-wrap">
        <div class="plan-detail pr">
<!--            <div class="page-header">-->
<!--                <el-page-header @back="goback()">-->
<!--                    <span slot="content" style="font-weight:bold">方案详情</span>-->
<!--                </el-page-header>-->
<!--            </div>-->
            <div class="collect-box">
                <el-button v-if="collection" type="text" :icon="'el-icon-star-off'"
                           @click="collectionControl">收藏产品
                </el-button>
                <el-button v-else type="text" :icon="'el-icon-star-on'" @click="removeCollect">已收藏
                </el-button>
            </div>
            <div class="mt-4">
                <div class="f24 fwb tac pb-2" style="color: #000">{{ dataForm.serviceName }}</div>
                <div class="uf uf-ac uf-pc mb-4 mt-3 op-08">
                    <i class="iconfont icon-company-fill mr-2" style="font-size: 18px"></i>
                    <div class="f16">{{dataForm.enterpriseName}}</div>
                </div>
                <my-editor-view :content="dataForm.itemDetail"></my-editor-view>
            </div>
        </div>
    </div>
</template>

<script>
import MyEditorView from '@/components/my-editor-view.vue'
export default {
    components: {
        MyEditorView
    },
    data () {
        return {
            collection: false,
            visible: false,
            detail: '',
            dataForm: []
        }
    },
    created () {
        this.initDetail(this.id)
    },
    computed: {
        //存取用户登录状态
        loginStatus: {
            get() {
                return this.$store.state.user.loginStatus
            }
        },
        userDiscountEntityList: {
            get() {
                return this.$store.state.user.userDiscountEntityList
            },
            set(val) {
                this.$store.commit('user/updateUserDiscountEntityList', val)
            }
        },
        //接受路由传参 服务产品channel_item_code获取服务详情
        id() {
            return this.$route.query.id
        }
    },
    methods: {
        init (item) {
            this.visible = true
            item.itemDetail = item.itemDetail || item.productDetail
            item.serviceName = item.serviceName || item.productName
            this.detail = item
        },
        initDetail(id) {
            Object.assign(this.$data, this.$options.data())
            this.loading = true
            this.$http({
                url: this.$http.adornUrl(this.$api.PUB.SERVICE_INFO + '/' + id),
                method: 'POST'
            }).then(({data}) => {
                if (data && data.code === 0) {
                    this.dataForm = data.serviceInfo
                    // 静态例子
                    // 价格类型： 每件 等
                    this.priceTypeName = this.dataForm.skuList[0].priceTypeName

                    // 服务地区
                    this.attarAreaList = data.serviceInfo.attributeAreaList

                    // if (this.attarAreaList.length > 0 && this.attarAreaList[0].children.length === 0) {
                    //   delete this.attarAreaList[0]['children']
                    //   this.attarArea.push('全国')
                    //   this.specificationBtn('全国', 0, 0)
                    // }

                    // 数据处理方法
                    this.dataProcessing()
                    this.checkItem()
                    this.processList = this.dataForm.serviceProcess ? this.dataForm.serviceProcess.split(',') : []
                    this.descUrlList = this.dataForm.descUrl ? this.dataForm.descUrl.split(',') : []
                    this.dataForm.descUrl = this.dataForm.descUrl ? this.dataForm.descUrl : ''
                    if (this.loginStatus) {
                        this.initCollect()
                    }
                }
                this.recommendService()
                this.loading = false
            })
        },
        initCollect() {
            this.$http({
                url: this.$http.adornUrl(this.$api.COLLECT.SEARCH),
                method: 'get',
                params: this.$http.adornParams({
                    'serviceId': this.dataForm.id
                })
            }).then(({data}) => {
                if (data) {
                    this.collection = false
                }
            })
        },
        collectionControl() {
            if (this.loginStatus) {
                this.$http({
                    url: this.$http.adornUrl(this.$api.COLLECT.SAVE),
                    method: 'POST',
                    data: this.$http.adornData({
                        'serviceId': this.dataForm.id,
                        'serviceName': this.dataForm.serviceName,
                        'itemCode': this.dataForm.itemCode,
                        'logoUrl': this.dataForm.itemUrl
                    })
                }).then(({data}) => {
                    if (data && data.code === 0) {
                        this.$message.success('已收藏')
                        this.collection = !this.collection
                    }
                })
            } else {
                this.$message.warning('该功能需要登录')
            }
        },
        // 删除
        removeCollect() {
            this.$http({
                url: this.$http.adornUrl(this.$api.COLLECT.REMOVE),
                method: 'post',
                params: this.$http.adornParams({
                    'serviceId': this.dataForm.id
                })
            }).then(({data}) => {
                if (data && data.code === 0) {
                    this.$message.info('已取消收藏')
                    this.collection = !this.collection
                } else {
                    this.$message.error(data.msg)
                }
            })
        },
        dataProcessing() {
            // 遍历skuList找到最大价格this.priceList.max和最小价格this.priceList.min
            this.dataForm.skuList.forEach((item, index) => {
                if (index === 0) {
                    this.priceList.max = item.price
                    this.priceList.min = item.price
                } else {
                    this.priceList.max = Math.max(this.priceList.max, item.price)
                    this.priceList.min = Math.min(this.priceList.min, item.price)
                }

                var abList = []
                var avList = []
                item.priceDesc.split(',').forEach(item => {
                    this.attributeList.push(item.split(':')[0])
                    this.attriValueList.push(item.split(':')[1])
                    abList.push(item.split(':')[0])
                    avList.push(item.split(':')[1])
                })

                // diffList： 所有价格组合{id: '', price: '', difference: ''}
                var map = {id: '', price: '', difference: ''}
                map.id = item.id
                map.price = item.price
                var str = []
                for (var i = 0; i < abList.length; i = i + 1) {
                    if (abList[i] === '服务地区') {
                        str[i] = avList[i].split('|')[1]
                    } else {
                        str[i] = avList[i]
                    }
                }
                map.difference = str.join(',')
                this.diffList.push(map)
            })

            // 折扣信息
            this.userDiscountEntityList.forEach(item => {
                if (item.discountProduct.serviceId === this.id && item.discountProduct.price < this.priceList.min) {
                    this.priceList.min = item.discountProduct.price
                }
            })

            // assortList：（key, value）格式，key为type，value为 [] ，包含了所有类型
            for (var j = 0; j < this.attributeList.length; j = j + 1) {
                if (this.assortList.get(this.attributeList[j])) {
                    if (this.attributeList[j] === '服务地区') {
                        this.assortList.get(this.attributeList[j]).push(this.attriValueList[j].split('|')[1])
                    } else {
                        this.assortList.get(this.attributeList[j]).push(this.attriValueList[j])
                    }
                    this.assortList.set(this.attributeList[j], [...new Set(this.assortList.get(this.attributeList[j]))])
                } else {
                    this.assortList.set(this.attributeList[j], [])
                    if (this.attributeList[j] === '服务地区') {
                        this.assortList.get(this.attributeList[j]).push(this.attriValueList[j].split('|')[1])
                    } else {
                        this.assortList.get(this.attributeList[j]).push(this.attriValueList[j])
                    }
                    this.assortList.set(this.attributeList[j], [...new Set(this.assortList.get(this.attributeList[j]))])
                }
            }

            this.attributeList = [...new Set(this.attributeList)]
            this.attributeList.forEach(val => {
                this.dataForm.attributeList.forEach(item => {
                    if(val === item.attributeName){
                        var map = {name: '', item: []}
                        map.name = item.attributeName
                        var list = item.attributeValue.split(',')
                        list.forEach(item => {
                            var itemName = {name: ''}
                            itemName.name = item
                            map.item.push(itemName)
                        })
                        this.specifications.push(map)
                    }
                })
            })


            this.simulatedDATA.difference = this.diffList
            this.simulatedDATA.specifications = this.specifications
            // this.simulatedDATA.specifications = this.simulatedDATA.specifications.filter(i => i.name !== '服务地区')
            for (var i in this.simulatedDATA.difference) {
                this.shopItemInfo[this.simulatedDATA.difference[i].difference] = this.simulatedDATA.difference[i]
            }

            // 默认第一个
            let s = this.diffList[0].difference.split(',')
            s.forEach((item, index) => {
                if(index === 0){
                    this.attarArea.push(item)
                    this.specificationBtn(item, 0, 0)
                } else {
                    this.specificationBtn(item, index, 0)
                }
            })
            // if (this.specifications[0].item.length === 1) {
            //   var area = this.specifications[0].item[0].name.split('|')[1]
            //   this.attarArea.push(area)
            //   console.log(area)
            //   this.specificationBtn(area, 0, 0)
            // }
        },
        addOrFollow() {
            if (this.loginStatus) {
                this.addOrUpdateVisible = true
                this.$nextTick(() => {
                    this.$refs['addNeed'].init({
                        serviceId: this.dataForm.id,
                        serviceName: this.dataForm.serviceName,
                        itemCode: this.dataForm.itemCode,
                        itemName: this.dataForm.itemName
                    })
                })
            } else {
                this.$message.error('请您登录账号')
                this.$router.push({name: 'login'})
            }
        },
        closeCallback(refresh) {
            this.addOrUpdateVisible = false
        },
        //立即购买
        purchase() {
            if (this.loginStatus) {
                var totalPrice = 0
                var disCount = 0
                if (this.discountProductPrice === -1) {
                    totalPrice = this.shopItemInfo[this.selectValue].price * this.count
                    disCount = 0
                } else {
                    if (this.discount === -1 ) {
                        totalPrice = this.discountProductPrice*this.count
                        disCount = this.count
                    } else {
                        totalPrice = this.discount < this.count ? this.discountProductPrice*this.discount + this.shopItemInfo[this.selectValue].price*(this.count-this.discount) : this.discountProductPrice*this.count
                        disCount = this.discount < this.count ? this.discount : this.count
                    }
                }
                let info = {
                    itemCode: this.dataForm.itemCode,
                    itemName: this.dataForm.itemName,
                    serviceId: this.dataForm.id,
                    serviceName: this.dataForm.serviceName,
                    skuId: this.shopItemInfo[this.selectValue].id,
                    price: this.shopItemInfo[this.selectValue].price,
                    quantity: this.count,
                    difference: this.shopItemInfo[this.selectValue].difference,
                    priceType: this.shopItemInfo[this.selectValue].priceType || null,
                    templateFilePath: this.dataForm.templateFilePath ?? null,
                    userDiscountId: this.userDiscountId ?? null,
                    discountProductPrice: this.discountProductPrice !== -1 ? this.discountProductPrice : 0,
                    discount: disCount,
                    totalSum: totalPrice,
                    contacts: this.name,
                    mobile: this.mobile,
                    email: this.email,
                    company :this.info.length === 1 ? this.info[0].enterpriseName : '无'
                }
                localStorage.setItem('payMentInfo', JSON.stringify(info))
                this.$router.push({
                    name: 'payment',
                })
            } else {
                this.$message.error('请您先登录')
                this.$router.push({name: 'login'})
            }
        },
        //加入购物车
        toShoppingCart() {
            if (this.loginStatus) {
                let _this = this
                this.$http({
                    url: this.$http.adornUrl(this.$api.SHOPPING_CART.SAVE),
                    method: 'POST',
                    data: this.$http.adornData({
                        'itemCode': this.dataForm.itemCode,
                        'serviceId': this.dataForm.id,
                        'skuId': this.shopItemInfo[this.selectValue].id,
                        'price': this.shopItemInfo[this.selectValue].price,
                        'count': this.count
                    })
                }).then(({data}) => {
                    if (data && data.code === 0) {
                        this.$message({
                            message: '成功加入购物车，您可以去购物车结算！',
                            type: 'success'
                        })
                    }
                })
            } else {
                this.$message.error('请您登录账号')
                this.$router.push({name: 'login'})
            }
        },
        goback() {
            //获取缓存
            let itemCode = localStorage.getItem('itemCode')
            // this.$router.go(-1)
            let itemCodeType = this.$route.query.itemCodeType
            if (itemCodeType==='search') {
                this.$router.push({ name: 'search', params: {itemCode: itemCode ? itemCode: null,},meta:{keepAlive: true} })
            }else if (itemCodeType === 'pay') {
                this.$router.push({ name: 'search', params: {itemCode: itemCode ? itemCode: null,},meta:{keepAlive: true} })
            }else{
                this.$router.go(-1)
            }
        },
        handleChange(value) {
            let item = this.$refs.tree.getCheckedNodes()[0].value
            let index = 0
            for (let shopItemInfoKey in this.shopItemInfo) {
                if (item === shopItemInfoKey.split(',')[0]) {
                    this.specificationBtn1(item, 0, index)
                    break
                }
                index = index + 1
            }

        },
        specificationBtn1(item, n, index) {
            var self = this
            self.selectArr[n] = item
            self.subIndex[n] = index
            self.checkItem()
        },
        specificationBtn(item, n, index) {
            var self = this
            if (self.selectArr[n] != item) {
                self.selectArr[n] = item
                self.subIndex[n] = index
            } else {
                self.selectArr[n] = ''
                self.subIndex[n] = -1 //去掉选中的颜色
            }
            self.checkItem()
        },
        pNameVisible (e, name, index) {
            let thisWidth = e.target.clientWidth
            let wordWidth = e.target.scrollWidth
            if (wordWidth > thisWidth + 5) {
                this.tooltipVisible[name + '-' + index] = true
                this.$forceUpdate()
            } else {
                this.tooltipVisible[name + '-' + index] = false
            }
        },
        checkItem() {
            var self = this
            var option = self.simulatedDATA.specifications
            var result = [] //定义数组储存被选中的值
            for (var i in option) {
                result[i] = self.selectArr[i] ? self.selectArr[i] : ''
            }
            for (var i in option) {
                var last = result[i] //把选中的值存放到字符串last去
                for (var k in option[i].item) {
                    result[i] = option[i].item[k].name //赋值，存在直接覆盖，不存在往里面添加name值
                    option[i].item[k].isShow = self.isMay(result) //在数据里面添加字段isShow来判断是否可以选择
                }
                result[i] = last //还原，目的是记录点下去那个值，避免下一次执行循环时被覆盖
            }
            this.selectValue = result
            if (this.shopItemInfo[result]) {
                this.discountProductPrice = -1
                this.discount = -1
                this.userDiscountId = ''
                this.ftime = ''
                this.userDiscountEntityList.forEach(item => {
                    if (item.discountProduct.skuId === this.shopItemInfo[result].id) {
                        this.discountProductPrice = item.discountProduct.price
                        this.discount = item.useTimes
                        this.userDiscountId = item.id
                        this.ftime = item.endTime
                    }
                })
                this.price = this.shopItemInfo[result].price || ''
                this.priceVisible = true
                this.payVisible = false
            } else {
                this.price = 0
                this.priceVisible = false
                this.payVisible = true
            }
            self.$forceUpdate() //重绘
        },
        isMay(result) {
            for (var i in result) {
                if (result[i] == '') {
                    return true //如果数组里有为空的值，那直接返回true
                }
            }
            return this.shopItemInfo[result] ? true : false
        },
        viewControl() {
            this.$http({
                url: this.$http.adornUrl(this.$api.VIEW.LIST),
                method: 'get',
                params: this.$http.adornParams({
                    'page': this.pageIndex,
                    'limit': 5
                })
            }).then(({data}) => {
                if (data && data.code === 0) {
                    this.viewList = data.page.list
                    this.viewSave()
                }
            })
        },
        viewSave() {
            this.$http({
                url: this.$http.adornUrl(this.$api.VIEW.SAVE),
                method: 'POST',
                data: this.$http.adornData({
                    'serviceId': this.dataForm.id,
                    'serviceName': this.dataForm.serviceName,
                    'itemCode': this.dataForm.itemCode,
                    'logoUrl': this.dataForm.itemUrl
                })
            }).then(({data}) => {
                if (data && data.code === 0) {
                }
            })
        }
    }
}
</script>
<style scoped lang="scss">
::v-deep .ql-editor {
    overflow: inherit;
}
.page-header {
    position: sticky;
    //width: 100%;
    top: 80px;
    left: auto;
    right: auto;
    padding: 15px 10px;
    background: #fff;
    box-shadow: 0 0 10px rgba(0,0,0,.1);
}
.plan-wrap {
    min-height: 98vh;
    background: url("../../assets/img/img04.jpg");
    background-size: 100% auto;
    padding-bottom: 30px;
}
.plan-detail {
    padding-top: 90px;
    width: 1325px;
    margin: 0 auto;
}
.collect-box {
    position: absolute;
    right: 0;
    top: 110px;
}
</style>
